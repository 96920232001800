import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss'],
})
export class MissionComponent  implements OnInit {

  // @Input() missions: any[] = [];

  // @Input() mision: any;

  @Input() item: any;

  constructor(private router: Router) { }

  ngOnInit() {
    // console.log(this.item.progress.state)
  }

  getStatusColor(status: string | null): string {
    if (!status) {
      return '#e74c3c';
    }

    let statusText = '';

    switch (status) {
      case 'STARTED':
        statusText = 'Iniciada';
        break;
      case 'IN_PROGRESS':
        statusText = 'En progreso';
        break;
      case 'COMPLETED':
        statusText = 'Completada';
        break;
      default:
        statusText = 'Pendiente';
      console.log(statusText)
    }

    switch (statusText) {
      case 'Pendiente':
        return '#e74c3c';
      case 'Iniciada':
        return '#f4d03f'
      case 'En progreso':
        return '#ff9800';
      case 'Completada':
        return '#145a32';
      default:
        return '#888';
    }
  }

  getStatusLabel(status: string | null): string {
    if (!status) {
      return 'Pendiente';
    }

    switch (status) {
      case 'STARTED':
        return 'Iniciada';
      case 'IN_PROGRESS':
        return 'En progreso';
      case 'COMPLETED':
        return 'Completada';
      default:
        return 'Pendiente';
    }
  }

  getProgressValue(progress: number): number {
    return progress / 100;
  }

  goToMisionDetails(id: any){
    this.router.navigate(['/mission-details/'+id])
  }

}
