<form [formGroup]="form">

  <div class="form-description mb-4" [innerHTML]="formDescription"></div>

  <div class="grid-principal-container-form">
    <div *ngFor="let question of questions">
      <div [ngSwitch]="question.type">

        <!-- text input -->
        <div *ngSwitchCase="'string'" class="form-group mb-4">
          <label>{{ question.label }}</label>
          <input
            type="text"
            [formControlName]="question.id"
            class="rounded"
          />
        </div>

        <!-- for unique selection -->
        <div *ngSwitchCase="'select'" class="form-group mb-4">
          <label>{{ question.label }}</label>
          <select
            [formControlName]="question.id"
            class="rounded"
          >
            <option *ngFor="let option of question.options" [value]="option">
              {{ option }}
            </option>
          </select>
        </div>

        <!-- input for date type -->
        <div *ngSwitchCase="'date'" class="form-group mb-4">
          <label>{{ question.label }}</label>
          <input
            type="date"
            [formControlName]="question.id"
            class="rounded"
          />
        </div>
      </div>

      <!-- errors -->
      <div
        *ngIf="
          form.get(question.id)?.touched &&
          form.get(question.id)?.invalid
        "
        class="text-red-500"
      >
        {{ getErrorText(question) }}
      </div>
    </div>
  </div>
</form>
