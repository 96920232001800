import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }


  clean(): void {
    window.localStorage.clear();
  }

  cleanCart(){
    localStorage.removeItem('cart');
  }

  saveUser(user: any): void {
    window.localStorage.removeItem('user');
    window.localStorage.setItem('user', JSON.stringify(user));
  }

  saveToken(token: string): void {
    window.localStorage.removeItem('token');
    window.localStorage.setItem('token', token);
  }

  saveRefreshToken(token: string): void {
    window.localStorage.removeItem('refresh_token');
    window.localStorage.setItem('refresh_token', token);
  }

  getUser(): any {
    const user = window.localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

  isLoggedIn(): boolean {
    const token = window.localStorage.getItem('token');
    // console.log(token);
    if (token) {
      return true;
    }
    return false;
  }

  getAuthHeaders() {

    let sessionToken = window.localStorage.getItem('token')
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionToken
      })
    }

    return httpOptions
  }


  getSimpleAuthHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'token ' + window.localStorage.getItem('token')
    })
    return headers
  }

}
