<form [formGroup]="form">
  <!-- Mostrar la descripción del formulario -->
  <div class="form-description mb-4" [innerHTML]="formDescription"></div>

  <div class="grid lg:grid-cols-2 grid-cols-1 gap-5 grid-principal-container-form">
    <div *ngFor="let question of questions">
      <div [ngSwitch]="question.resourcetype">
        <!-- Pregunta de Texto -->
        <div *ngSwitchCase="'TextQuestion'" class="mb-4">
          <label>{{ question.text }}</label>
          <input
            type="text"
            [formControlName]="question.id"
            class="w-full py-2 px-3 h-10 rounded border"
          />
        </div>

        <!-- Pregunta de Selección Única -->
        <div *ngSwitchCase="'UniqueQuestion'" class="mb-4">
          <label>{{ question.text }}</label>
          <select
            [formControlName]="question.id"
            class="w-full py-2 px-3 h-10 rounded border"
          >
            <option *ngFor="let option of question.options" [value]="option">
              {{ option }}
            </option>
          </select>
        </div>

        <!-- Pregunta de Imagen -->
        <div *ngSwitchCase="'ImageQuestion'" class="mb-4">
          <label>{{ question.text }}</label>
          <!-- <file-upload
          *ngIf="question.type_upload === 'file'"
          [(ngModel)]="uploadedFiles"
          (ngModelChange)="convertToBase64(question.id)"
          [name]="question.id.toString()"
          [multiple]="false"
          [accept]="'.jpg,.jpeg'"
          [ngModelOptions]="{ standalone: true }">

          <ng-template let-i="index" let-file="file" let-control="control" #item>
            <div class="overlay">

                @if (control.valid) {
                    <img [src]="this.form.get(question.id.toString())?.value">
                }

            </div>
          </ng-template>

        </file-upload> -->

        <div class="take-photo-container-question" *ngIf="question.id && !photo[question.id]" (click)="takePicture(question.id, question.type_upload)">
          <ion-icon name="camera-outline"></ion-icon>
          <p>{{ question.type_upload === 'file' ? 'Subir imagen' : (question.type_upload === 'camera' ? 'Tomar foto' : 'Subir o tomar foto') }}</p>
        </div>

        <div *ngIf="photo?.[question.id]" class="photo-preview-container">
          <div class="image-container">
            <img [src]="photo[question.id]" alt="foto tomada" class="captured-photo">
          </div>

          <ion-icon name="camera-outline" class="replace-photo-icon" (click)="takePicture(question.id, question.type_upload)"></ion-icon>

        </div>

      </div>

        <!-- Pregunta de Fecha -->
        <div
          *ngSwitchCase="'DateQuestion'"
          class="mb-4"
          id="date-{{ question.text }}"
        >
          <label>{{ question.text }}</label>
          <input
            type="date"
            [formControlName]="question.id"
            class="w-full py-2 px-3 h-10 rounded border"
          />
        </div>
      </div>

      <!-- Mostrar errores -->
      <div
        *ngIf="
          form.get(question.id.toString())?.touched &&
          form.get(question.id.toString())?.invalid
        "
        class="text-red-500"
      >
        {{ getErrorText(question) }}
      </div>
    </div>
  </div>
</form>
