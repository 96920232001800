import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CatalogService } from './catalog.service';
import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MissionsService {


  constructor(private storageSvc: StorageService, private http: HttpClient,) { }

  getMissions(){
    const url = environment.url + '/seth/missions/?limit=20&offset=0';
    const httpOptions = this.storageSvc.getAuthHeaders();

    return this.http.get(url, httpOptions).toPromise().then((data) => {
      return data;
    });
  }

  getMissionDetails(id: number){
    const url = environment.url + '/seth/missions/' + id;
    const httpOptions = this.storageSvc.getAuthHeaders();

    return this.http.get(url, httpOptions).toPromise().then((data) => {
      return data;
    });
  }


  submitSurvey(surveyData: { mission: number; survey: any; answers: { question_id: any; content: any; }[]; }) {
    const url = environment.url + '/seth/response/answers/';
    const httpOptions = this.storageSvc.getAuthHeaders();
    return this.http.post(url,surveyData, httpOptions).toPromise().then((data) => {
      return data;
    });
  }

  enrollMissionByUser(surveyData: { mission_id:number}) {
    const url = environment.url + '/seth/response/enroll/';
    const httpOptions = this.storageSvc.getAuthHeaders();
    return this.http.post(url,surveyData, httpOptions).toPromise().then((data) => {
      return data;
    });
  }


  updateStep(data: { mission_response_id: number; actual_step: number;  }) {
    const url = environment.url + '/seth/response/update-step/';
    const httpOptions = this.storageSvc.getAuthHeaders();
    return this.http.post(url,data, httpOptions).toPromise().then((data) => {
      return data;
    });
  }

  completedMission(data: { mission_response_id: number;  }) {
    const url = environment.url + '/seth/response/complete/';
    const httpOptions = this.storageSvc.getAuthHeaders();
    return this.http.post(url,data, httpOptions).toPromise().then((data) => {
      return data;
    });
  }

}
