<div class="relative md:-mt-48 -mt-32 w-full max-w-xs mx-auto">
    <div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
        <div class="profile-pic text-center mb-5">
            <input id="pro-img" name="profile-image" type="file" class="hidden" (change)="loadFile($event)" />
            <div>
                <!-- <div class="relative h-28 w-28 mx-auto">
                    <img [src]="user?.profile ? user?.profile :'assets/images/user.png'" class="rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800 w-full h-full object-cover" id="profile-image" alt="">
                    <label class="absolute inset-0 cursor-pointer" for="pro-img"></label>
                </div> -->

                <div class="mt-4">
                    <h5 class="text-lg font-semibold">{{user?.first_name | titlecase}} {{user?.last_name | titlecase}}</h5>
                    <p class="text-slate-400">{{user?.email}}</p>
                </div>
            </div>
        </div>

        <div class="border-t border-gray-100 dark:border-gray-700">
            <ul class="list-none sidebar-nav mb-0 pb-0" id="navmenu-nav">
                <li class="navbar-item account-menu" [ngClass]="current.includes('user') ? 'active' : '' ">
                    <a (click)="goUrl('profile', {tab: 'user'})" routerLink="/user-account" class="navbar-link text-slate-400 flex items-center py-2 rounded info">
                        <span class="me-2 mb-0"><i data-feather="user" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Perfil</h6>
                    </a>
                </li>

                <li class="navbar-item account-menu" [ngClass]="current.includes('transactions') ? 'active' : '' ">
                    <a (click)="goUrl('profile', {tab: 'transactions'})" class="navbar-link text-slate-400 flex items-center py-2 rounded info">
                        <span class="me-2 mb-0"><i data-feather="gift" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Transacciones</h6>
                    </a>
                </li>

                <li class="navbar-item account-menu" [ngClass]="current.includes('redemptions') ? 'active' : '' ">
                    <a (click)="goUrl('profile', {tab: 'redemptions'})" class="navbar-link text-slate-400 flex items-center py-2 rounded info">
                        <span class="me-2 mb-0"><i data-feather="dollar-sign" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Redenciones</h6>
                    </a>
                </li>

                <!-- <li class="navbar-item account-menu" [ngClass]="current ===  '/user-setting' ? 'active' : '' ">
                    <a routerLink="/user-setting" class="navbar-link text-slate-400 flex items-center py-2 rounded">
                        <span class="me-2 mb-0"><i data-feather="settings" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Settings</h6>
                    </a>
                </li> -->

                <li class="navbar-item account-menu">
                    <a (click)="logout()" class="navbar-link text-slate-400 flex items-center py-2 rounded info">
                        <span class="me-2 mb-0"><i data-feather="log-out" class="size-4"></i></span>
                        <h6 class="mb-0 font-medium">Cerrar sesión</h6>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
