import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MissionsService } from 'src/app/services/missions.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements AfterViewInit  {

  @Input() steps: string[] = [];
  @Input() currentStep: number = 1;
  @Input() missionId: number = 1;
  @Input() rewardPoints: number = 0;
  @Input() rewardType: string = '';
  @Output() currentStepChange = new EventEmitter<number>();
  @Output() prevStepEvent = new EventEmitter<number>();
  @Output() nextStepEvent = new EventEmitter<number>();
  @Output() finishMission = new EventEmitter<boolean>();
  @Input() isDisableNext: boolean = true;
  totalSteps = 0;
  @ViewChild('swiper') swiperContainerRef!: ElementRef;
  formData: any;

  constructor(public missionSvc: MissionsService, private router: Router){}

  ngAfterViewInit(): void {
    this.totalSteps = this.steps.length;
    console.log('los steps', this.steps)
    console.log('step actual',this.currentStep)
  }

  nextStep() {
    if (this.currentStep < this.totalSteps) {
      this.currentStep++;
      this.currentStepChange.emit(this.currentStep);
      this.nextStepEvent.emit(this.currentStep);

      if (this.currentStep >= this.totalSteps - 1 || this.currentStep <= 2) {
        this.swiperContainerRef?.nativeElement?.swiper.slideNext();
      } else if (this.currentStep % 2 === 0) { // Avanzar cada 2 pasos en los demás casos
        this.swiperContainerRef?.nativeElement?.swiper.slideNext();
      }

    }
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
      this.currentStepChange.emit(this.currentStep);
      this.prevStepEvent.emit(this.currentStep);

      if (this.currentStep <= 2 || this.currentStep >= this.totalSteps - 1) {
        this.swiperContainerRef?.nativeElement?.swiper.slidePrev();
      } else if (this.currentStep % 2 === 0) { // Retroceder cada 2 pasos en los demás casos
        this.swiperContainerRef?.nativeElement?.swiper.slidePrev();
      }
    }
  }

  finish() {
    this.finishMission.next(true)
  }

  goToDetails(){
    console.log('el mission id:', this.missionId)
    this.router.navigate(['/mission-details/' + this.missionId])
  }

}
