import { Component, OnInit, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-profile-data',
  templateUrl: './profile-data.component.html',
  styleUrls: ['./profile-data.component.scss'],
})
export class ProfileDataComponent implements OnInit, AfterViewInit {
  @Output() scrolTop = new EventEmitter<any>();
  userForm: FormGroup;
  profileFields: any[] = [];
  isSubmitting = false;
  isLoading: boolean = true;
  bannerColor: any;
  userLevel: any;

  constructor(private fb: FormBuilder, private authService: AuthService, private toolsService: ToolsService) {
    this.userForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.loadProfileFields();
    this.getUserLevel()
  }

  ngAfterViewInit(): void {}

  async loadProfileFields() {
    this.isLoading = true;
    try {
      const response = await this.authService.getProfileFields();
      this.profileFields = response;
      console.log(this.profileFields)

      this.profileFields.forEach(field => {
        let validators = [];
        if (field.required) {
          validators.push(Validators.required);
        }
        if (field.type === 'email') {
          validators.push(Validators.email);
        }

        const control = this.fb.control(
          { value: field.value || '',
          disabled: field.disabled || false },
          validators
        );


        this.userForm.addControl(field.name, control);
        this.isLoading = false;
        this.scrolTop.next(1000)
      });
    } catch (error) {
      console.error('Error al cargar los campos del perfil:', error);
      this.isLoading = false;
    }
  }

  async onSubmit() {
    if (this.userForm.valid) {
      this.isSubmitting = true;
      await this.toolsService.loadingPresent('Actualizando...');

      try {
        const formData = this.createFormData();
        await this.authService.createOrUpdateProfile(formData);
        this.toolsService.presentSuccefulToast('Perfil actualizado correctamente');
      } catch (error) {
        console.error('Error al actualizar el perfil:', error);
        this.toolsService.presentErrorToast('Error al actualizar el perfil');
      } finally {
        this.isSubmitting = false;
        this.toolsService.loadingDismiss();
      }
    } else {
      this.userForm.markAllAsTouched();
    }
  }

  createFormData() {
    const formData: any = {};
    for (const field of this.profileFields) {
      formData[field.name] = this.userForm.get(field.name)?.value;
    }
    return formData;
  }

  isStringArray(options: any[]): boolean {
    return options.length > 0 && typeof options[0] === 'string';
  }

  async getUserLevel(){
    this.userLevel = await this.authService.getUserLevel();
  }

}
