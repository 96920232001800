import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  @Output() scrolTop = new EventEmitter<any>();
  transactions: any[] = [];
  currentPage = 1;
  itemsPerPage = 2;
  totalPages = 1;
  isLoading = false;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.fetchTransactions();
  }

  async fetchTransactions() {
    try {
      this.isLoading = true;
      const filters = {
        limit: this.itemsPerPage,
        offset: (this.currentPage - 1) * this.itemsPerPage
      };
      const response = await this.authService.getTransactions(filters);
      this.transactions = response.results.sort((a: any, b: any) => new Date(b.created).getTime() - new Date(a.created).getTime());
      this.totalPages = Math.ceil(response.count / this.itemsPerPage);
      this.scrolTop.next(1000)
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      this.isLoading = false;
    }
  }

  async changePage(page: number) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    await this.fetchTransactions(); // Load transactions for the selected page
  }

  getTransactionType(amount: number): string {
    return amount < 0 ? 'Redención' : 'Puntos obtenidos';
  }

  getTransactionClass(amount: number): string {
    return amount < 0 ? 'text-red-600 dark:text-red-400' : 'sucess';
  }

  
}
