<div class="stepper-container">
  <ng-container *ngIf="steps.length <= 3; else swiperStepper">
    <ng-container *ngFor="let step of steps; let i = index">
      <div
        class="step"
        [ngClass]="{
          completed: currentStep > i + 1,
          active: currentStep === i + 1
        }"
      >
        <div class="circle">
          <ion-icon
            *ngIf="currentStep > i + 1"
            name="checkmark"
            class="check-icon"
          ></ion-icon>
          <span *ngIf="currentStep <= i + 1" class="step-number">{{
            i + 1
          }}</span>
        </div>
        <p class="step-label">{{ step }}</p>
      </div>
      <div
        *ngIf="i < steps.length - 1"
        class="line"
        [ngClass]="{ 'completed-line': currentStep > i + 1 }"
      ></div>
    </ng-container>
  </ng-container>

  <ng-template #swiperStepper>
    <swiper-container
      #swiper
      [slidesPerView]="3"
      [centeredSlides]="false"
      [spaceBetween]="10"
      [pagination]="false"
      [navigation]="false"
      [loop]="false"
    >
      <ng-container *ngFor="let step of steps; let i = index">
        <swiper-slide>
          <div
            class="step"
            [ngClass]="{
              completed: currentStep > i + 1,
              active: currentStep === i + 1
            }"
          >
            <div class="circle">
              <ion-icon
                *ngIf="currentStep > i + 1"
                name="checkmark"
                class="check-icon"
              ></ion-icon>
              <span *ngIf="currentStep <= i + 1" class="step-number">{{
                i + 1
              }}</span>
            </div>
            <p class="step-label">{{ step }}</p>
          </div>
          <div
            *ngIf="i < steps.length - 1"
            class="line"
            [ngClass]="{ 'completed-line': currentStep > i + 1 }"
          ></div>
        </swiper-slide>
      </ng-container>
    </swiper-container>
  </ng-template>
</div>

<div class="mission-started-container">
  <ion-card class="flex-card ion-padding" style="padding: 40px">


    <ng-container *ngIf="currentStep > steps.length; else notCompleted">
      <p class="completion-message"> <ion-icon slot="start" name="medal-outline"></ion-icon> Misión Completada</p>
      <p style="text-align: center;">🎉 ¡Felicidades! Has ganado <strong>{{ rewardPoints }}</strong> {{ rewardType }} 🎊</p>
      <div class="mt-5 go-details-button">
        <ion-button shape="round" (click)="goToDetails()">Ver detalles</ion-button>
      </div>
    </ng-container>

    <ng-template #notCompleted>
      <div class="title">Paso {{currentStep}} : {{steps[currentStep-1]}}</div>
      <ng-content #name></ng-content>

      <div class="navigation-buttons">
         <!-- <ion-button shape="round" expand="block" fill="clear" (click)="previousStep()" *ngIf="currentStep > 1">Anterior</ion-button>     -->
        <ion-button
          shape="round"
          expand="block"
          (click)="nextStep()"
          *ngIf="currentStep < totalSteps"
          [disabled]="isDisableNext"
          >Siguiente</ion-button
        >
        <ion-button
          shape="round"
          expand="block"
          [disabled]="isDisableNext"
          *ngIf="currentStep === totalSteps"
          (click)="finish()"
          >Finalizar</ion-button
        >
      </div>
    </ng-template>


  </ion-card>
</div>
