import { Injectable } from '@angular/core';
import { ToolsService } from './tools.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isUserLog: boolean = false;
  optionOnServer = '1';
  user: any;

  constructor(private toolsSvc: ToolsService, private http: HttpClient, private storageService: StorageService) {}

  async getCode(params: any) {
    const url = environment.url + '/anubis/auth/login';
    return this.http
      .post(url, params)
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  async validateCode(code: string) {
    const url = environment.url + '/anubis/auth/login/code';
    return this.http
      .post(url, {code})
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  async getLoginOption() {
    return this.optionOnServer;
  }

  getUserInfo() {
    const url = environment.url + '/anubis/auth/user';
    const httpOptions = this.storageService.getAuthHeaders();
    return lastValueFrom(this.http.get(url, httpOptions)).then(
      (response: any) => {
        // console.log(response);
        this.user = response;
        this.storageService.saveUser(response);
        return response;
      }
    );
  }

  async isLogged() {
    // If the user is loaded, return true
    if (this.user) {
      return true
    };
    try {
      let user = await this.getUserInfo();
    } catch (error) {
      console.debug('Error', error);
      return false;
    }
    return true;
  }


  logout() {
    this.user = null;
    this.storageService.clean();
  }

  refreshToken(token?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json; version=ClientesV1',
        'Authorization': 'Bearer ' + window.sessionStorage.getItem('refresh_token')
      }),
    };
    const url = environment.url + '/anubis/auth/refresh';

    return this.http.post(url, { }, httpOptions);
  }


  getBalance() {
    const url = environment.url + '/amun/rewards/balance';
    const httpOptions = this.storageService.getAuthHeaders();
    return lastValueFrom(this.http.get(url, httpOptions)).then(
      (response: any) => {
        // console.log(response);
        return response;
      }
    );
  }


  getSales(params: any){
    const url = environment.url + '/amun/sales';
    const httpOptions = this.storageService.getAuthHeaders();

    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        httpParams = httpParams.append(key, params[key]);
      }
    });

    const options = {
      ...httpOptions,
      params: httpParams,
    };
    return lastValueFrom(this.http.get(url, options)).then(
      (response: any) => {
        return response;
      }
    );
  }

  getTransactions(params: any){
    const url = environment.url + '/amun/rewards/transactions';
    const httpOptions = this.storageService.getAuthHeaders();

    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        httpParams = httpParams.append(key, params[key]);
      }
    });
    const options = {
      ...httpOptions,
      params: httpParams,
    };
    return lastValueFrom(this.http.get(url, options)).then(
      (response: any) => {
        return response;
      }
    );
  }


  getProfileFields(all: boolean = true) {
    const url = environment.url + '/horus/crm/profile/fields?all='+all;
    const httpOptions = this.storageService.getAuthHeaders();
    return lastValueFrom(this.http.get(url, httpOptions)).then(
      (response: any) => {
        // console.log(response);
        return response;
      }
    );
  }

  createOrUpdateProfile(body: any){
    const url = environment.url + '/horus/crm/profile/';
    const httpOptions = this.storageService.getAuthHeaders();
    return lastValueFrom(this.http.post(url,body, httpOptions)).then(
      (response: any) => {
        // console.log(response);
        return response;
      }
    );
  }

  async getLoginMethods(data: any) {
    const url = environment.url + '/anubis/auth/login/v2';
    return this.http
      .post(url, data)
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  async sendCode(data: any) {
    const url = environment.url + '/anubis/auth/login/v2/send-code/';
    return this.http
      .post(url, data)
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  getUserLevel(){
    const url = environment.url + '/amun/core/user-level'
    const httpOptions = this.storageService.getAuthHeaders();

    return lastValueFrom(this.http.get(url, httpOptions)).then(
      (response: any) => {
        return response;
      }
    );
  }

  verifyCodeFromPreRegister(data: any): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ valid: true });
      }, 1000);
    });
  }

  async loginLite(data: any) {
    // console.log('data en el lite login', data)
    const url = environment.url + '/anubis/auth/login/lite';
    return this.http
      .post(url, data)
      .toPromise()
      .then((data) => {
        return data;
      });
  }

}
