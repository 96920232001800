import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
date:any
company: any;
programVariables: any;

constructor(public configService: ConfigService) {}
ngOnInit(): void {
  this.date = new Date().getFullYear()
  this.company = this.configService.company;
  this.programVariables = this.configService.programVariables;
}

shoppingData = [
  {
    id:1,
    data:['Men','Jackets & Coats','Jeans','Loungewear','Polo shirts','Shirts']
  },
  {
    id:2,
    data:['Shorts','Suits Swimwear','T-shirts','Tracksuits','Trousers','Shirts']
  },
  {
    id:3,
    data:['My account','Order History','Wish List','Newsletter','Affiliate','Returns']
  },
]

payment = [
  'assets/images/payments/american-express.jpg','assets/images/payments/discover.jpg','assets/images/payments/mastercard.jpg','assets/images/payments/paypal.jpg','assets/images/payments/visa.jpg'
]
}
