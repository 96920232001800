<ion-modal class="platform-modal ion-padding" [isOpen]="showMessage" (ionModalDidDismiss)="closeModal()">
  <ion-header>
    <ion-toolbar>
      <h2 class="modal-title">Atención: Requisito de Plataforma</h2>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div *ngIf="showMessage" class="modal-content">
      <p class="modal-description">
        Has accedido a esta aplicación desde una plataforma que no es móvil.
        Algunas funcionalidades pueden estar optimizadas únicamente para dispositivos móviles y tablets.
      </p>

      <ul class="modal-instructions">
        <li>Para la mejor experiencia, se recomienda acceder desde un teléfono móvil o una tablet.</li>
        <li>En caso de dudas, comunícate con el soporte técnico para obtener ayuda adicional.</li>
      </ul>

      <ion-button (click)="closeModal()">Entendido</ion-button>
    </div>
  </ion-content>
</ion-modal>


