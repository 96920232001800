import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Output } from '@angular/core';

import { RouterLink, Router, ActivatedRoute  } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-account-tab',
  //standalone: true,
  //imports: [CommonModule,RouterLink],
  templateUrl: './account-tab.component.html',
  styleUrl: './account-tab.component.scss'
})
export class AccountTabComponent {
  image:any  = '';
  user: any;
  
  loadFile(event:any){
   this.image = document.getElementById(event.target.name);
   this.image.src = URL.createObjectURL(event.target.files[0]);
  }

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private storageService: StorageService) {
    this.route.queryParams.subscribe(params => {
      if(params?.['tab']){
        this.current = params['tab'];
      } 
    });
    this.getUser()
  }

  current:string = '';
  ngOnInit() {
    this.current = this.router.url;
  }

  async getUser(){
    this.user = await this.storageService.getUser()
  }

  goUrl(url: string, queryParams?: any) {
   
    this.router.navigate([url], {queryParams});
  }


  logout() {
    this.authService.logout();
    this.router.navigate(['/login'])
  }
}
