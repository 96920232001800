<ion-app>
  <div class="spinner-container" *ngIf="!isLoaded">
    <a class="logo">
      <div>
        <img
          [src]="
            configService.company?.logo
              ? configService.company?.logo
              : configService.company?.customer?.logo
          "
          class="h-[32px]"
          alt=""
        />
      </div>
    </a>
    <ion-spinner name="dots" color="primary"></ion-spinner>
    Cargando
  </div>
  <app-navbar
    [navLight]="true"
    [isSticky]="isSticky"
    [navClass]="'defaultscroll is-sticky'"
    (goUp)="scrollToTop()"
    *ngIf="isLoaded && showNavbar"
  ></app-navbar>
  <ion-content
    [fullscreen]="true"
    [scrollEvents]="false"
    (ionScroll)="onContentScroll($event)"
    class="home-content"
    *ngIf="isLoaded"
  >
    <ion-router-outlet ></ion-router-outlet>
  </ion-content>
</ion-app>
