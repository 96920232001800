<div
  class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900"
>

  <div
    *ngIf="false"
    class="p-4 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mb-4"
  >
    <h6 class="text-lg font-semibold">Balance de puntos</h6>
    <div class="mt-2">
      <div class="flex justify-between text-gray-500 dark:text-gray-400">
        <span>Puntos obtenidos</span>
        <span>0</span>
      </div>
      <div class="flex justify-between text-gray-500 dark:text-gray-400 mt-2">
        <span>Puntos canjeados</span>
        <span>0</span>
      </div>
      <div class="flex justify-between text-gray-500 dark:text-gray-400 mt-2">
        <span>Puntos expirados</span>
        <span>0</span>
      </div>
      <div
        class="flex justify-between text-black dark:text-white mt-4 border-t pt-2"
      >
        <span class="font-semibold">Saldo de puntos</span>
        <span>0</span>
      </div>
    </div>
  </div>
  <div
    class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900"
  >
    <h5 class="text-lg font-semibold mb-4">Transacciones</h5>

    <div *ngIf="isLoading" class="text-center">
      <ion-spinner name="crescent"></ion-spinner>
      <p class="mt-2">Cargando transacciones...</p>
    </div>

    <div *ngIf="!isLoading && transactions.length === 0" class="text-center">
      <p>No hay transacciones disponibles.</p>
    </div>

    <div *ngIf="!isLoading && transactions.length > 0">
      <div
        *ngFor="let transaction of transactions"
        class="p-4 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mb-4"
      >
        <div class="flex justify-between items-center">
          <div>
            <h6 class="text-lg font-semibold">
              {{ getTransactionType(transaction.amount) }}
            </h6>
            <span class="text-gray-500 dark:text-gray-400">{{
              transaction.created | date : "dd-MM-yyyy"
            }}</span>
          </div>
          <div class="text-right">
            <span
              [ngClass]="getTransactionClass(transaction.amount)"
              class="font-semibold"
            >
              <ion-icon [color]="transaction.amount < 0 ?  'danger' : 'success' " slot="icon-only" name="star-outline"  ></ion-icon>
              <ion-label [color]="transaction.amount < 0 ?  'danger' : 'success' ">
                {{ transaction.amount | number }} Puntos
              </ion-label> 
            </span>
          </div>
        </div>
        <div class="text-gray-500 dark:text-gray-400 mt-2">
          {{ transaction.description }}
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-12 grid-cols-1 mt-6" *ngIf="totalPages > 1">
      <div class="md:col-span-12 text-center">
        <nav aria-label="Page navigation example">
          <ul class="inline-flex items-center -space-x-px">
            <li>
              <a
                class="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 custom-hover-bg"
                (click)="changePage(currentPage - 1)"
                [class.disabled]="currentPage === 1"
              >
                <ion-icon
                  slot="icon-only"
                  name="chevron-back-outline"
                ></ion-icon>
              </a>
            </li>
            <li *ngFor="let page of [].constructor(totalPages); let i = index">
              <a
                class="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 custom-hover-bg"
                [ngClass]="{
                  'bg-orange-500 text-white': currentPage === i + 1
                }"
                (click)="changePage(i + 1)"
                >{{ i + 1 }}</a
              >
            </li>
            <li>
              <a
                class="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 custom-hover-bg"
                (click)="changePage(currentPage + 1)"
                [class.disabled]="currentPage === totalPages"
              >
                <ion-icon
                  slot="icon-only"
                  name="chevron-forward-outline"
                ></ion-icon>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
