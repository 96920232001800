import { Component, ViewChild } from '@angular/core';
import { IonContent, Platform } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { ConfigService } from './services/config.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild(IonContent) content!: IonContent;
  isLoaded: boolean = false;
  isSticky!: boolean;
  showNavbar!: boolean;
  constructor(private platform: Platform, public configService: ConfigService, private router: Router) {
    this.initializeApp();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Verifica si la ruta actual es '/login'
        this.showNavbar = !this.router.url.includes('/login');
      }
    });
    this.configService.companyLoaded.subscribe((company) => {
     setTimeout(() => {
      // console.log("LOADED", company);
      this.isLoaded = company ? true : false;
     }, 1000);
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.configService
        .loadCompany()
        .then((company) => {
          console.debug(company);
        })
        .catch((err) => {
          console.debug(err);
        });
    });
  }

  onContentScroll(event: any) {
    this.isSticky = event.detail.scrollTop > 0;
  }

  scrollToTop() {
    this.content.scrollToTop(400);
  }
}
