<div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900" *ngIf="!isLoading">
  <div *ngIf="userLevel.image !== null || undefined || '' " class="relative overflow-hidden md:rounded-md shadow dark:shadow-gray-700 h-52 bg-center bg-no-repeat bg-cover mb-4" style="background-image: url('{{userLevel.image}}'); aspect-ratio: 4 / 2; max-width: 100%; background-size: contain !important; margin: 0 auto;"
  [ngClass]="!bannerColor ? 'bg-gray-100' : ''" [ngStyle]="{ 'background-color': bannerColor }">
</div>

  <h5 class="text-lg font-semibold mb-4">Información del usuario</h5>
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="grid lg:grid-cols-2 grid-cols-1 gap-5" *ngIf="profileFields.length > 0">
      <div *ngFor="let field of profileFields">
        <label class="form-label font-medium">{{field.label}} <span *ngIf="field.required" class="text-red-600">*</span></label>
        <div class="mt-2">

          <!-- Text, Email, Phone inputs -->
          <input *ngIf="field.type === 'text' || field.type === 'email' || field.type === 'phone'"
            [type]="field.type === 'phone' ? 'tel' : field.type"
            [formControlName]="field.name"
            class="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border focus:ring-0"
            [ngClass]="{'border-danger focus:border-danger': userForm.get(field.name)?.invalid && userForm.get(field.name)?.touched, 'focus:border-primary focus:shadow-primary': !(userForm.get(field.name)?.invalid && userForm.get(field.name)?.touched)}"
            [placeholder]="field.label"
            [id]="field.name"
            [name]="field.name"
            [required]="field.required"
            [disabled]="field.disabled"
          />

          <!-- Date input -->
          <input *ngIf="field.type === 'date'"
            type="date"
            [formControlName]="field.name"
            class="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border focus:ring-0"
            [ngClass]="{'border-danger focus:border-danger': userForm.get(field.name)?.invalid && userForm.get(field.name)?.touched, 'focus:border-primary focus:shadow-primary': !(userForm.get(field.name)?.invalid && userForm.get(field.name)?.touched)}"
            [placeholder]="field.label"
            [id]="field.name"
            [name]="field.name"
            [required]="field.required"
            [disabled]="field.disabled"
          />

          <!-- Checkbox -->
          <div *ngIf="field.type === 'check'" class="flex items-center mt-4">
            <input
              type="checkbox"
              [formControlName]="field.name"
              class="form-checkbox h-5 w-5 text-orange-500"
              [id]="field.name"
              [name]="field.name"
              [disabled]="field.disabled"
            />
            <label for="{{field.name}}" class="ml-2 text-gray-700 dark:text-gray-200">{{field.label}}</label>
          </div>

          <!-- Select input -->
          <select *ngIf="field.type === 'select'"
            [formControlName]="field.name"
            class="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border focus:ring-0"
            [ngClass]="{'border-danger focus:border-danger': userForm.get(field.name)?.invalid && userForm.get(field.name)?.touched, 'focus:border-primary focus:shadow-primary': !(userForm.get(field.name)?.invalid && userForm.get(field.name)?.touched)}"
            [id]="field.name"
            [name]="field.name"
            [required]="field.required"
            [disabled]="field.disabled"
          >
            <option value="" disabled selected>Seleccione {{field.label}}</option>

            <!-- Para un array de strings -->
            <ng-container *ngIf="isStringArray(field.options); else objectOptions">
              <option *ngFor="let option of field.options" [value]="option">{{ option }}</option>
            </ng-container>

            <!-- Para un array de objetos -->
            <ng-template #objectOptions>
              <option *ngFor="let option of field.options" [value]="option.value" [disabled]="option.disabled">{{ option.label }}</option>
            </ng-template>
          </select>

          <div *ngIf="userForm.get(field.name)?.touched">
            <div *ngIf="userForm.get(field.name)?.errors?.['email']" class="text-red-500">
              {{field.label}} no es un correo electrónico válido.
            </div>
            <div *ngIf="!userForm.get(field.name)?.errors?.['email'] && userForm.get(field.name)?.errors?.['required']" class="text-red-500">
              {{field.label}} es requerido.
            </div>
          </div>

        </div>
      </div>
    </div>
    <button type="submit" [disabled]="isSubmitting" class="flex items-center justify-center py-2 px-5 font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md mt-5">
      <ion-spinner color="white" *ngIf="isSubmitting" name="lines-small" class="mr-2"></ion-spinner>
      <span>Actualizar datos</span>
    </button>
  </form>
</div>
