import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private menuCtrl: MenuController, private configService: ConfigService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let isLogged = await this.authService.isLogged();
   // this.configService.company && (this.configService.company.is_public = false);
    if (isLogged || this.configService.company?.is_public) {
      return true;
    }
    this.router.navigate(['login'])
    return false;
  }
}
