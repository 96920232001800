<footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
    <div class="py-[30px] px-0 border-t border-slate-800">
        <div class="container relative">
            <div class="grid grid-cols-12">
                <div class="col-span-12 flex flex-col items-center justify-center">
                    <a class="focus:outline-none mb-4 flex items-center justify-center rounded-full overflow-hidden logo-test" style="width: 120px; height: 120px; background-color: transparent; border: 3px solid var(--ion-color-primary); padding: 6px;">
                        <div class="flex items-center justify-center bg-white rounded-full" style="width: 100%; height: 100%;">
                            <img [src]="configService.company?.customer?.logo" alt="" style="max-width: 80%; max-height: 80%;">
                        </div>
                    </a>
                    <!-- Separador -->
                    <div id="submitsubscribe" class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center text-white rounded-md">
                        © {{date}} <a href="https://mallgo.co" target="_blank">MallGo</a>. Powered <i class="mdi mdi-heart text-red-600"></i> by
                        <a href="https://inkremental.co" target="_blank" class="text-reset">Inkremental</a><br>
                        <a [href]="programVariables.terms_and_conditions" target="_blank">Terminos y condiciones</a> |
                        <a [href]="company.customer.privacy_policy" target="_blank">Política de Privacidad</a>
                    </div>
                </div>
            </div><!--end grid-->
        </div><!--end container-->
    </div>
</footer>
