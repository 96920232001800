import { Component, OnInit, AfterViewChecked, ElementRef, ViewChildren, QueryList, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-redemptions',
  templateUrl: './redemptions.component.html',
  styleUrls: ['./redemptions.component.scss']
})
export class RedemptionsComponent implements OnInit, AfterViewChecked {
  @Output() scrolTop = new EventEmitter<any>();
  orders: any[] = [];
  paginatedOrders: any[] = [];
  currentPage = 1;
  itemsPerPage = 2;
  totalPages = 1;
  isLoading = false;
  max_days_to_deliver: any;

  @ViewChildren('orderItem') orderItems!: QueryList<ElementRef>;
  isUserScrolling!: boolean;

  constructor(private authService: AuthService, private router: Router, private configService: ConfigService) {}

  ngOnInit() {
    this.fetchOrders();
  }

  ngAfterViewChecked() {
  }

  async fetchOrders() {
    try {
      const filters = {
        ordering: 'id',
        limit: this.itemsPerPage,
        offset: (this.currentPage - 1) * this.itemsPerPage
      };
      this.isLoading = true;
      const response = await this.authService.getSales(filters);
      this.orders = response.results.sort((a: any, b: any) => new Date(b.created).getTime() - new Date(a.created).getTime());
      this.totalPages = Math.ceil(response.count / this.itemsPerPage);
      this.paginatedOrders = this.orders;
      this.scrolTop.next(1000)
      this.max_days_to_deliver = this.orders.reduce((acc: any, order: any) => {
        let products = order.product_orders;
        let days = this.getMaxDaysToDeliver(products);
        acc[order.id] = days;
        return acc;
      }, {});
      console.log(this.orders)
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      this.isLoading = false;
    }
  }

  changePage(page: number) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.fetchOrders();
  }

  getStatusMessage(state: string): string {
    const statusMessages: any = {
      "canceled": "Cancelado",
      "delivered": "Entregado",
      "in_warehouse": "En centro comercial",
      "supplier_requested": "Alistando el producto",
      "supplier_shipped": "Enviado",
      "not_processed": "Pendiente por procesar",
      "pending_approval": "Pendiente aprobación",

    };
    return statusMessages[state] || 'Estado desconocido';
  }

  goDetail(id: number) {
    this.router.navigate(['/product-detail/' + id]);
  }

  // getMaxDaysToDeliver(products: any) {
  //   if (!products || products.length === 0) {
  //     return null;
  //   }

  //   // Crear un objeto para almacenar el id y el max `days_to_deliver`
  //   let daysByProductId = products.reduce((acc: any, product: any) => {
  //     // Obtener el id del producto
  //     const productId = product.id;

  //     // Obtener el valor de `days_to_deliver` o 0 si no está disponible
  //     const daysToDeliver = product.product?.days_to_deliver || 0;

  //     // Si el id ya existe, comparar el valor actual con el almacenado y guardar el mayor
  //     if (acc[productId]) {
  //       acc[productId] = Math.max(acc[productId], daysToDeliver);
  //     } else {
  //       // Si no existe, simplemente asignar el valor
  //       acc[productId] = daysToDeliver;
  //     }

  //     return acc;
  //   }, {});

  //   return daysByProductId;
  // }

  getMaxDaysToDeliver(products: any): number {
    if (!products || products.length === 0) {
      return 0; // Cambié de null a 0 para evitar problemas en Math.max
    }

    // Extraer todos los `days_to_deliver` de `product.product`
    return Math.max(
      ...products.map((product: any) => product.product?.days_to_deliver || 0)
    );
  }

}
