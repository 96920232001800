import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface Question {
  id: string;
  type: string;
  label: string;
  validation_error: string;
  options?: string[];
}

@Component({
  selector: 'app-pre-register-form',
  templateUrl: './pre-register-form.component.html',
  styleUrls: ['./pre-register-form.component.scss'],
})
export class PreRegisterFormComponent  implements OnInit {
  @Input() questions: Question[] = [];
  @Input() formDescription: string = '';
  @Output() formData = new EventEmitter<any>();
  form!: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.buildForm();
    // console.log('campos del pre-registro',this.questions);
    // console.log('el form',this.form)
    this.form.valueChanges.subscribe(() => {
      this.formData.emit(this.form.value);
    });

  }

  // private buildForm() {
  //   const group: { [key: string]: any } = {};
  //   this.questions.forEach((question) => {
  //     const controlValidators = [];
  //     if (question.type === 'string') {
  //       controlValidators.push(Validators.required);
  //     }

  //     group[question.id] = this.fb.control('', controlValidators);
  //   });
  //   this.form = this.fb.group(group);
  // }

  private buildForm() {
    if (!this.questions || this.questions.length === 0) {
      console.error('No se encontraron preguntas');
      return;
    }

    // console.log('Preguntas recibidas:', this.questions);

    const group: { [key: string]: any } = {};
    this.questions.forEach((question) => {
      if (!question.id || !question.type) {
        // console.error('error en questions:', question);
        return;
      }

      const controlValidators = [];
      if (question.type === 'text') {
        controlValidators.push(Validators.required);
      }

      group[question.id] = this.fb.control('', controlValidators);
    });

    this.form = this.fb.group(group);
  }


  getErrorText(question: Question): string {
    if (this.form.get(question.id)?.hasError('required')) {
      return question.validation_error || 'Este campo es obligatorio';
    }
    return '';
  }

  onFileChange(event: Event, controlName: string) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.form.get(controlName)?.setValue(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }


}
