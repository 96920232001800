<h5 class="text-2xl font-semibold">{{product. name}}</h5>
<div class="mt-2">
  <div>
      <span class="text-slate-400 font-semibold me-1">
          <ion-icon color="primary" name="star-outline"></ion-icon>
          {{product.points | number}} puntos
      </span>
  </div>
  <div>
      <span class="text-slate-400 font-semibold me-1">
          Este producto es de tipo {{ product.is_coupon ? 'digital' : 'físico' }}
      </span>
  </div>
</div>


<div class="mt-4">
    <h5 class="text-lg font-semibold">Descripción general :</h5>
    <p class="text-slate-400 mt-2" [innerHTML]="product.description"></p>
</div>

<div class="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-4">

    <div class="flex items-center">
        <!-- <h5 class="text-lg font-semibold me-2">Quantity:</h5> -->
        <div class="qty-icons ms-3 space-x-0.5">
            <button (click)="decreaseQuantity() " class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md  minus">-</button>
            <input min="0" name="quantity" [(ngModel)]="quantity" type="number" class="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md  hover:text-white pointer-events-none w-16 ps-4 quantity">
            <button (click)="increaseQuantity()" class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5  plus">+</button>
        </div>
    </div><!--end content-->
</div><!--end grid-->

<div class="mt-4 space-x-1">
    <a class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center rounded-md bg-orange-500 text-white mt-2 justify-center cursor-pointer " (click)="presentAddToCartAlert(product.is_coupon)"> {{!catalogService.isProductInCart(product.id) ? 'Agregar' : 'Agregado' }}  a carrito </a>
</div>

<!-- <app-adicional-info-form *ngIf="isModalOpen" [isModalOpen]="isModalOpen" [product]="product"></app-adicional-info-form> -->


