import { Component, OnInit, Input  } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-platform-mobile-modal',
  templateUrl: './platform-mobile-modal.component.html',
  styleUrls: ['./platform-mobile-modal.component.scss'],
})
export class PlatformMobileModalComponent  implements OnInit {

  @Input() showMessage: boolean = false;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    // console.log('el show message', this.showMessage)
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
