<div class="information-container">
  <h2 class="information-tittle">INFORMACIÓN</h2>
  <div class="inputs-container">
    <ion-list>
      <ion-item lines="none">
        <ion-label position="stacked">Nombre</ion-label>
        <ion-input type="text" placeholder="Ingresa tu nombre"></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">Apellidos</ion-label>
        <ion-input type="text" placeholder="Ingresa tus apellidos"></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">Email</ion-label>
        <ion-input type="text" placeholder="Ingresa tu Email"></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">Tipo de identificacion</ion-label>
        <ion-input type="text" placeholder="Selecciona un tipo de documento"></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">Celular</ion-label>
        <ion-input type="text" placeholder="Ingresa tu numero de celular"></ion-input>
      </ion-item>
      <!-- <ion-item lines="none">
        <ion-label position="stacked">Género</ion-label>
        <ion-input type="text" placeholder="Selecciona tu genero"></ion-input>
      </ion-item> -->
      <ion-item lines="none">
        <ion-label position="stacked">Numero identificacion</ion-label>
        <ion-input type="text" placeholder="Ingresa tu numero de identificacion"></ion-input>
      </ion-item>
    </ion-list>
  </div>
</div>
