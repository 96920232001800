import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Product } from 'src/app/interfaces/products.interface';
import { CatalogService } from 'src/app/services/catalog.service';
import { ToolsService } from 'src/app/services/tools.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
  @Input() product!: Product;
  isModalOpen!: boolean;
  quantity: number = 1;
  constructor(public catalogService: CatalogService, private toolService: ToolsService, private alertController: AlertController,) {}

  ngOnInit() {
    this.product.quantity = 1;
  }

  increaseQuantity(): void {
    if (this.quantity !== undefined && this.quantity < this.product?.stock) {
      this.quantity++;
    }
  }

  decreaseQuantity(): void {
    if (this.quantity !== undefined && this.quantity > 1) {
      this.quantity--;
    }
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }


  addCart() {
    this.product.quantity = this.quantity;

    if (this.product.stock === null || this.product.stock === undefined || this.quantity <= this.product.stock) {
        this.catalogService.addToCart(this.product, this.quantity);
    } else {
        this.toolService.presentErrorToast(`No se puede agregar más de la cantidad disponible (${this.product.stock}) en stock`);
    }
  }

  async presentAddToCartAlert(productType: any) {
    let message = ''
    if(productType == true) {
      message = `Este producto es un bono digital. Recibirás un código en tu correo electrónico para redimirlo.`;
    } else {
      message = `Este es un producto físico. Podrás recogerlo en la administración del centro comercial cuando recibas el correo de confirmación.`;
    }

    const alert = await this.alertController.create({
      header: 'Confirmacion',
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            alert.dismiss();
          }
        },
        {
          text: 'Continuar',
          handler: () => {
            this.addCart()
          }
        }
      ]
    });

    await alert.present();
  }

}
