 <ion-card class="item-card">
  <div class="status-label" [ngStyle]="{'background-color': getStatusColor(item.progress?.state)}">
    {{ getStatusLabel(item.progress?.state) || 'Pendiente' }}
  </div>


  <img [alt]="item.title" [src]="item.image || 'https://ionicframework.com/docs/img/demos/thumbnail.svg'" />

  <div class="data-container">

    <!-- <div class="progress-container" *ngIf="item.progress !== undefined"> -->
    <div class="progress-container">
      <ion-progress-bar [value]="getProgressValue(item.progress?.progress || 0)"></ion-progress-bar>
    </div>

    <ion-card-header>
      <ion-card-title class="card-title">{{ item.title }}</ion-card-title>
    </ion-card-header>

    <ion-card-content class="points" *ngIf="item.pendingPeople">
      Tenderos inscritos: <ion-icon name="person-circle-outline"></ion-icon>{{ item.pendingPeople }}
    </ion-card-content>

    <ion-card-content class="points">
      <!-- <ion-card-content class="points" *ngIf="item.reward !== undefined"> -->
      <!-- Premio: <ion-icon name="star-outline" color="primary"></ion-icon> {{ item.reward }} puntos -->
      Premio: <ion-icon name="star-outline" color="primary"></ion-icon> {{ item.rewards_value }} puntos
    </ion-card-content>

    <!-- <div *ngIf="item.progress !== undefined">
      <ion-card-content class="training-container">
        <p class="training-description "> <b>Descripción:</b> {{ item.description }}</p>
        <p> <b>Fecha vigencia:</b> {{ item.startDate }}</p>
      </ion-card-content>
    </div> -->
  </div>

  <ion-button shape="round" expand="block" (click)="goToMisionDetails(item.id)">Detalles</ion-button>
</ion-card>
