import { AfterViewInit, Component, OnInit, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { IonPopover } from '@ionic/angular';
import * as feather from 'feather-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent  implements OnInit , AfterViewInit{
  @ViewChild('popover', { static: false }) popover!: IonPopover;

  products = [
    {
      name: 'Producto1',
      price: 126000,
      image: 'https://docs-demo.ionic.io/assets/madison.jpg',
      discount: 100,
      total: 0
    },
    {
      name: 'Producto2',
      price: 126000,
      image: 'https://docs-demo.ionic.io/assets/madison.jpg',
      discount: 100000,
      total: 0
    },
    {
      name: 'Producto3',
      price: 126000,
      image: 'https://docs-demo.ionic.io/assets/madison.jpg',
      discount: 0,
      total: 0
    }
  ]

  constructor(
    private router: Router
  ) { }

  ngAfterViewInit() {
   // feather.replace();
  }

  ngOnInit() {}

  openPopover(ev: Event) {
    this.popover.event = ev;
    this.popover.present();
  }

  goToFilter() {
    this.router.navigate(['/catalog']);
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  goToShoppingCart(){
    this.router.navigate(['/shopping-cart']);
  }

  goToProfile(){
    this.router.navigate(['/profile']);
  }

  goToHelp(){
    this.router.navigate(['/help']);
  }

  goToLogin(){
    this.router.navigate(['/login']);
  }

}
