import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  activateCameraSizeValidation: boolean = false;

  constructor() { }


  async takePicture(source_type: any) {
    let data = await Camera.checkPermissions();
    console.debug('PictureService.takePicture() - Permissions', data);

    if (!data.camera) {
      await Camera.requestPermissions();
    }

    let source: CameraSource;
    switch (source_type) {
      case 'camera':
        source = CameraSource.Camera;
        break;
      case 'file':
        source = CameraSource.Photos;
        break;
      case 'both':
      default:
        source = CameraSource.Prompt;
        break;
    }

    try {
      this.activateCameraSizeValidation = true;
      this.validateCameraSize();

      const image = await Camera.getPhoto({
        source,
        quality: 100,
        correctOrientation: true,
        allowEditing: false,
        width: 720,
        height: 1280,
        resultType: CameraResultType.Uri,
        promptLabelPhoto: 'Seleccionar desde galería',
        promptLabelPicture: 'Tomar foto',
        promptLabelCancel: 'Cancelar',
      });

      this.activateCameraSizeValidation = false;

      console.debug('PictureService.takePicture()', image);

      if (!image.webPath) return;

      return this.resizeImage(image.webPath, 1280, 1280);
    } catch (error) {
      this.activateCameraSizeValidation = false;
      console.warn(error);
      return;
    }
  }


  /**
   * This function is used to validate the camera modal size on big screen's devices
   * @returns
   */
  validateCameraSize() {
    if (!this.activateCameraSizeValidation) return;
    let cameraElement: any = document.querySelector('pwa-camera-modal-instance');
    try {
      cameraElement.shadowRoot.childNodes[0].childNodes[0].setAttribute("style", "width:100%;height:100%;");
    } catch (error) {
      // console.debug('PictureService.validateCameraSize() - Error', error);
      setTimeout(() => {
        this.validateCameraSize();
      }, 500);
      return;
    }
  }

  /**
   * Validates the image size and returns a base64 string
   * @param originalImg Given image url
   * @param newWidth Maximum width
   * @param newHeight Maximum height
   * @returns Base64 string
   */
  resizeImage(originalImg: string, newWidth: number, newHeight: number): Promise<string> {
    return new Promise(async (resolve) => {
      var img = document.createElement('img');
      img.onload = () => {
        var canvas = document.createElement('canvas');
        var ctx: any = canvas.getContext('2d');
        var iw = img.width;
        var ih = img.height;
        var scale = Math.min((newWidth / iw), (newHeight / ih));
        if (newWidth > iw || newHeight > ih) scale = 1
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        if (img.width < newWidth || img.height < newHeight) ctx.drawImage(img, 0, 0, img.width, img.height);
        else ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        var dataURI = canvas.toDataURL('image/jpeg', 0.9);
        resolve(dataURI);
      };
      img.src = originalImg;

    })
  }

}
