<ion-header [translucent]="true">
  <ion-toolbar>
    <div class="toolbar-container principal-container">
      <div slot="start" class="logo-container">
        <img src="https://upload.wikimedia.org/wikipedia/commons/8/85/Logo-Test.png" alt="logo" (click)="goToHome()">
      </div>

      <div class="categories-container">
        <ion-item>
          <ion-select label="Buscar por categoría" label-placement="floating" interface="popover" (ionChange)="goToFilter()">
            <ion-select-option value="1">Belleza</ion-select-option>
            <ion-select-option value="2">Viajes</ion-select-option>
            <ion-select-option value="3">Deportes</ion-select-option>
            <ion-select-option value="4">Equipos móviles</ion-select-option>
          </ion-select>
        </ion-item>
      </div>

      <div class="searchbar-container">
        <ion-searchbar show-clear-button="always" placeholder="Buscar producto" class="searchbar"></ion-searchbar>
      </div>

      <!-- <ion-item [button]="true" id="shopping-cart-modal" lines="none" class="shopping-cart-modal-container">
        <div class="shopping-cart-container">
          <ion-icon class="menu-button" name="cart-outline"></ion-icon>
          <p>3</p>
        </div>
      </ion-item> -->

      <ion-item [button]="true" lines="none" class="shopping-cart-modal-container">
        <div class="shopping-cart-container"  (click)="goToShoppingCart()">
          <ion-icon class="menu-button" name="cart-outline"></ion-icon>
          <p>3</p>
        </div>
      </ion-item>

      <!-- <ion-item [button]="true" id="user-options-modal" lines="none" class="user-info-modal-container">
        <div slot="end" class="user-info-container">
          <ion-icon class="menu-button" name="person-circle-outline"></ion-icon>
          <div class="user-container">
            <p class="user-name">Hola, User</p>
            <p><ion-icon name="star-outline"></ion-icon>Tienes 200 puntos</p>
          </div>
          <ion-icon class="menu-button" name="chevron-down-outline"></ion-icon>
        </div>
      </ion-item> -->

      <ion-item [button]="true" id="user-options-modal" lines="none" class="user-info-modal-container" (click)="openPopover($event)">
        <div slot="end" class="user-info-container">
          <ion-icon class="menu-button" name="person-circle-outline"></ion-icon>
          <div class="user-container">
            <p class="user-name">Hola, User</p>
            <p><ion-icon name="star-outline"></ion-icon>Tienes 200 puntos</p>
          </div>
          <ion-icon class="menu-button" name="chevron-down-outline"></ion-icon>
        </div>
      </ion-item>


    </div>
  </ion-toolbar>
</ion-header>

<!-- <ion-popover trigger="user-options-modal" [dismissOnSelect]="true" side="bottom" size="cover">
  <ng-template>
    <ion-content>
      <ion-list>
        <ion-item lines="none" [button]="true" (click)="goToProfile()">
          <ion-icon class="log-out-button" name="person-circle-outline" slot="start"></ion-icon>
          Perfil
        </ion-item>
        <ion-item lines="none" [button]="true" (click)="goToHelp()">
          <ion-icon class="log-out-button" name="help-circle-outline" slot="start"></ion-icon>
          Ayuda
        </ion-item>
        <ion-item lines="none" class="divider" [button]="true" [detail]="false" (click)="goToLogin()">
          Cerrar Sesión
          <ion-icon class="log-out-button" name="log-out-outline" slot="end"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover> -->

<ion-popover #popover [dismissOnSelect]="true" side="bottom" size="auto">
  <ng-template>
    <ion-content>
      <ion-list>
        <ion-item lines="none" [button]="true" (click)="goToProfile()">
          <ion-icon class="log-out-button" name="person-circle-outline" slot="start"></ion-icon>
          Perfil
        </ion-item>
        <ion-item lines="none" [button]="true" (click)="goToHelp()">
          <ion-icon class="log-out-button" name="help-circle-outline" slot="start"></ion-icon>
          Ayuda
        </ion-item>
        <ion-item lines="none" class="divider" [button]="true" [detail]="false" (click)="goToLogin()">
          Cerrar Sesión
          <ion-icon class="log-out-button" name="log-out-outline" slot="end"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>

<ion-popover trigger="shopping-cart-modal" [dismissOnSelect]="true" side="bottom">
  <ng-template>
    <ion-content>
      <ion-list>
        <ion-item [button]="true" (click)="goToShoppingCart()">
          Ver carrito de compras
        </ion-item>
        <ion-item [button]="true" *ngFor="let product of products">
          {{ product.name }} - {{ product.price }}
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>
