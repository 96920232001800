import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Categories } from '../interfaces/category.interface';
import { Product } from '../interfaces/products.interface';
import { ModalController } from '@ionic/angular';
import { AdicionalInfoFormComponent } from '../components/adicional-info-form/adicional-info-form.component';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  private cart = JSON.parse(sessionStorage.getItem('cart') as any) || [];
  public cartItemCount = new BehaviorSubject<number>(this.getCartItemCount(this.cart));
  cartItemCount$ = this.cartItemCount.asObservable();
  categoriesLoaded: Subject<Categories[]> = new Subject<Categories[]>();
  //loadingCompany: boolean = true;
  categories?: Categories[];
  constructor(
    private http: HttpClient,
    private modalController: ModalController,
    private storageService: StorageService
  ) { }

  getAuthHeaders() {
    let sessionToken = window.sessionStorage.getItem('token');
    console.debug('sessionToken', sessionToken);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionToken,
      }),
    };

    return httpOptions;
  }

  getCartItemCount(cart: any): number {
    return cart.reduce((count: any, item: { quantity: any; }) => count + item.quantity, 0);
  }

  getCategories() {
    const url = environment.url + '/amun/catalog/categories';
    const httpOptions = this.getAuthHeaders();

    return this.http
      .get(url, httpOptions)
      .toPromise()
      .then((data) => {
        this.categories = data as Categories[];
        this.categoriesLoaded.next(data as Categories[]);
        return data;
      });
  }

  getProducts(params: any) {
    const url = environment.url + '/amun/catalog/products';
    const httpOptions = this.storageService.getAuthHeaders();

    // Crear HttpParams a partir del objeto params
    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        if (key === 'ordering') {
          httpParams = httpParams.append(`-${key}`, params[key]);
        } else {
          httpParams = httpParams.append(key, params[key]);
        }
      }
    });

    // Añadir los parámetros de consulta a httpOptions
    const options = {
      ...httpOptions,
      params: httpParams,
    };

    return this.http
      .get(url, options)
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  getProductByid(id: number) {
    const url = environment.url + '/amun/catalog/products/' + id;
    const httpOptions = this.getAuthHeaders();

    return this.http
      .get(url, httpOptions)
      .toPromise()
      .then((data) => {
        return data as Product;
      });
  }

  getBanners() {
    const url = environment.url + '/amun/core/banner/';
    const httpOptions = this.getAuthHeaders();

    return this.http
      .get(url, httpOptions)
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  async addToCart(product: Product, quantity: number = 1, showModal: boolean = true) {
    // Retrieve the cart from sessionStorage, or initialize it as an empty array
    const cart: Product[] = JSON.parse(sessionStorage.getItem('cart') ?? '[]');
    // Check if the product already exists in the cart
    const existingProduct = cart.find(item => item.id === product.id);
    // If the product has additional info, isn't in the cart yet, and showModal is true
    if (product.additional_info?.length && !existingProduct && showModal) {
      // Open a modal to collect additional info from the user
      const data = await this.openModal(product);
      console.debug('addToCart data', data);
      if (!data) return; // Exit if the user cancels or no data is returned
      product.additional_info_request = data.additional_info_request;
    }
    console.debug('addToCart product', product, existingProduct);
    // If the product already exists in the cart
    if (existingProduct) {
      if (!existingProduct.quantity) existingProduct.quantity = 0
      // Increase the quantity of the existing product in the cart
      existingProduct.quantity += quantity;
    } else {
      // Set the quantity for the new product and add it to the cart
      product.quantity = quantity;
      cart.push(product);
    }
    // Save the updated cart back to sessionStorage
    sessionStorage.setItem('cart', JSON.stringify(cart));
    this.cartItemCount.next(this.getCartItemCount(cart));
  }


  isProductInCart(productId: number): boolean {
    const cart = JSON.parse(sessionStorage.getItem('cart') as any) || [];
    return cart.some((item: { id: number; }) => item.id === productId);
  }

  async openModal(product: Product): Promise<Product | undefined> {
    // Create the modal with the specified component and properties
    const modal = await this.modalController.create({
      component: AdicionalInfoFormComponent,
      componentProps: { product },
      cssClass: 'confirm-password-modal'
    });
    // Present the modal to the user
    await modal.present();
    // Wait for the modal to be dismissed and get the returned data
    const { data: returnedProduct } = await modal.onDidDismiss();
    // If no data is returned, exit the function
    if (!returnedProduct) return undefined;
    // Log the returned product
    console.log('Returned Product:', returnedProduct);
    // Return the updated product
    return returnedProduct;
  }

  getCart() {
    return JSON.parse(sessionStorage.getItem('cart') as any) || [];
  }

  setCart(cart: Product) {
    sessionStorage.setItem('cart', JSON.stringify(cart));
    this.cartItemCount.next(this.getCartItemCount(cart));
  }

  getAddress() {
    const url = environment.url + '/amun/core/address';
    const httpOptions = this.storageService.getAuthHeaders();
    return lastValueFrom(this.http.get(url, httpOptions)).then(
      (response: any) => {
        return response;
      }
    );
  }

  createOrder(data: any) {
    const url = environment.url + '/amun/sales';
    const httpOptions = this.storageService.getAuthHeaders();
    console.log(data)
    return lastValueFrom(this.http.post(url, data, httpOptions)).then(
      (response: any) => {
        return response;
      }
    );
  }
}
