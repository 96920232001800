import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Color, ColorVariable, RGB } from '../interfaces/color';
import { BrandColors, Company } from '../interfaces/company.interface';
import { Program } from '../interfaces/program.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  companyLoaded: Subject<Company> = new Subject<Company>();
  public scrollTop = new BehaviorSubject<number>(400);
  scrollTop$ = this.scrollTop.asObservable();
  loadingCompany: boolean = true;
  company?: Company;
  programVariables: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public httpClient: HttpClient,
    private titleService: Title
  ) { }

  /**
   * Returns the company object if it is already loaded, otherwise loads it from the server
   * @returns Promise<company>
   */
  loadCompany(): Promise<Company> {
    if (this.company) return Promise.resolve(this.company);
    return new Promise((resolve, reject) => {
      this.getCompany().subscribe({
        next: (data) => {
          // console.log('Company:', data);
          this.loadingCompany = false;
          this.company = data;
          this.loadProgramVariables()
          this.setPageTitle();
          this.loadMallColors();
          this.changeFavicon(this.company.favicon ? this.company.favicon : this.company.customer.favicon ? this.company.customer.favicon : 'assets/icon/favicon.png');
          this.companyLoaded.next(this.company);
          this.addScript(this.company.website_scripts);
          resolve(this.company);
        },
        error: (err) => {
          this.loadingCompany = false;
          reject(err);
        },
      });
    });
  }

  /**
   * Returns the company object from the server
   * @returns Observable<company>
   */
  getCompany(): Observable<Company> {
    let url = `${environment.url}/osiris/customer/channel/`;
    return this.httpClient.get<Company>(url, {}).pipe(retry(2));
  }

  /**
   * Sets the page title
   * @param title
   */
  public setPageTitle(title?: string) {
    let companyName = this.company?.name || this.company?.customer?.name || 'MallGo';
    if (title) this.titleService.setTitle(title + ' | ' + companyName);
    else this.titleService.setTitle(companyName);
  }

  /**
   * Changes the favicon dynamically
   * @param faviconUrl
   */
  changeFavicon(faviconUrl: string) {
    let link: HTMLLinkElement =
      this.document.querySelector("link[rel*='icon']") ||
      this.document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = faviconUrl;
    this.document.head.appendChild(link);
  }

  loadMallColors() {
    const brandColors = this.company?.brand_colors && !this.isEmptyObject(this.company?.brand_colors)
      ? this.company?.brand_colors
      : this.company?.customer?.brand_colors;

    if (!brandColors) return;
    for (let colorName in brandColors) {
      let colorValue = brandColors[colorName as keyof BrandColors];
      try {
        this.loadWebsiteColor(colorName, colorValue);
      } catch (error) {
        console.warn('Error setting color', colorName, colorValue);
      }
    }
  }

  isEmptyObject(obj: object): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }


  //Function to set company primary and secondary color
  loadWebsiteColor(colorName: string, colorValue: string) {
    let colorData = this.generateColor(colorValue);
    this.document.documentElement.style.setProperty(
      '--ion-color-' + colorName,
      colorData.value ?? null
    );
    this.document.documentElement.style.setProperty(
      '--ion-color-' + colorName + '-rgb',
      colorData.valueRgb ?? null
    );
    this.document.documentElement.style.setProperty(
      '--ion-color-' + colorName + '-contrast',
      colorData.contrast ?? null
    );
    this.document.documentElement.style.setProperty(
      '--ion-color-' + colorName + '-contrast-rgb',
      colorData.contrastRgb ?? null
    );
    this.document.documentElement.style.setProperty(
      '--ion-color-' + colorName + '-shade',
      colorData.shade ?? null
    );
    this.document.documentElement.style.setProperty(
      '--ion-color-' + colorName + '-tint',
      colorData.tint ?? null
    );
  }

  private generateColor(value: string): ColorVariable {
    const color = new Color(value);
    const contrast = color.contrast();
    const tint = color.tint();
    const shade = color.shade();
    const formattedValue = value.startsWith('#') ? value : `#${value}`;

    return {
      value: formattedValue,
      valueRgb: this.rgbToString(color.rgb),
      contrast: contrast.hex,
      contrastRgb: this.rgbToString(contrast.rgb),
      tint: tint.hex,
      shade: shade.hex,
    };
  }

  private rgbToString(c: RGB): string {
    return `${c.r},${c.g},${c.b}`;
  }

  // Method to dynamically add and execute a script in the head
  addScript(htmlScript: string) {
    if (!htmlScript) return; // Exit if no script is provided

    console.log("Appending to HEAD:", htmlScript);

    // Append the script to the head of the document
    document.head.insertAdjacentHTML('beforeend', htmlScript);

    // Replace all script tags within .customScript elements
    this.replaceScriptsInNodes(document.querySelectorAll(".customScript"));
  }

  // Replace script tags within the provided nodes
  replaceScriptsInNodes(nodes: any) {
    nodes.forEach((node: { tagName: string; replaceWith: (arg0: HTMLScriptElement) => void; }) => {
      console.log("Processing node:", node);

      // Check if the node is a script tag, then clone and replace it
      if (node.tagName === 'SCRIPT') {
        console.log("Replacing script tag");
        node.replaceWith(this.cloneScript(node));
      }
    });
  }

  // Clone the script tag to ensure it executes again
  cloneScript(node: any) {
    const script = document.createElement("script"); // Create new script element
    script.textContent = node.textContent; // Copy the content of the original script

    // Copy all attributes from the original node to the new script tag
    [...node.attributes].forEach(attr => script.setAttribute(attr.name, attr.value));

    return script; // Return the new script element
  }

  loadProgramVariables() {
    console.debug('Loading program variables');
    if (this.programVariables) return this.programVariables;
    let url = `${environment.url}/amun/core/program/`;
    this.httpClient.get<Program>(url, {}).pipe(retry(2)).subscribe({
      next: (data) => {
        // console.log('Program:', data);
        this.programVariables = data;
      },
      error: (err) => {
        console.error('Error loading program variables', err);
      },
    });
  }

  validateBudget() {
    console.debug('Loading budget on program');
    if (this.programVariables) return this.programVariables;
    let url = `${environment.url}/amun/core/program/`;
    this.httpClient.get<Program>(url, {}).pipe(retry(2)).subscribe({
      next: (data) => {
        console.log('Program:', data);
        this.programVariables = data;
      },
      error: (err) => {
        console.error('Error loading program variables', err);
      },
    });
  }

}
