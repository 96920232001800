import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Product } from 'src/app/interfaces/products.interface';

@Component({
  selector: 'app-adicional-info-form',
  templateUrl: './adicional-info-form.component.html',
  styleUrls: ['./adicional-info-form.component.scss'],
})
export class AdicionalInfoFormComponent implements OnInit {
  @Input() product!: Product;
  @Input() isModalOpen = true;
  @Input() additionalInfoForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    const formControls = this.product.additional_info.reduce((acc, info) => {
      acc[info.key] = [
        info.type === 'select' ? info.options[0] : '',
        info.required ? Validators.required : null,
      ];
      return acc;
    }, {});

    this.additionalInfoForm = this.formBuilder.group(formControls);
    console.log(this.additionalInfoForm)
  }

  openModal() {
    this.isModalOpen = true;
  }

  // closeModal() {
  //   this.modalCtrl.dismiss(this.product);
  // }

  // onSubmit() {
  //   this.additionalInfoForm.markAllAsTouched();
  //   if (this.additionalInfoForm.valid) {
  //     this.product.additional_info_request = this.additionalInfoForm.value;
  //     this.closeModal();
  //   }
  // }

  closeModal(sendProduct: boolean = false) {
    if (sendProduct) {
      this.modalCtrl.dismiss(this.product);
    } else {
      this.modalCtrl.dismiss();
    }
  }

  onSubmit() {
    this.additionalInfoForm.markAllAsTouched();
    if (this.additionalInfoForm.valid) {
      this.product.additional_info_request = this.additionalInfoForm.value;
      //booleano to close the modal correctly
      this.closeModal(true);
    }
  }

}
