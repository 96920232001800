<nav
  id="topnav"
  class="{{ navClass }}"
  [ngClass]="isSticky ? 'nav-sticky' : ''"
>
  <div class="container relative">
    <!-- Logo container-->
    <!-- End Logo container-->

    <!-- Start Mobile Toggle -->

    <!-- End Mobile Toggle -->

    <!--Login button Start-->

    <!--Login button End-->

    <div
      class=""
      style="
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      "
    >
      <div class="menu-extras">
        <div class="menu-item">
          <a
            class="navbar-toggle"
            [ngClass]="toggleManu ? 'open' : ''"
            id="isToggle"
            (click)="toggleMenu()"
          >
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </div>
      </div>
      <div id="navigation" [ngClass]="toggleManu ? 'block' : 'none'">
        <!-- Navigation Menu-->
        <ul class="navigation-menu custom-menu">
          <li
            class="has-submenu parent-parent-menu-item"
            (click)="goUrl('/home'); categorySelected = null"
          >
            <a class="logo" >
              <div class="option-menu">
                <img
                  [src]="
                    configService.company?.logo
                      ? configService.company?.logo
                      : configService.company?.customer?.logo
                  "
                  class="h-[32px]"
                  [ngClass]="{ 'h-[100%]': isMobile }"
                  alt=""
                />
              </div>
            </a>
          </li>
          <li
            class="has-submenu parent-parent-menu-item"
            [ngClass]="['/home'].includes(manu) ? 'active' : ''"
          >
            <a
              [ngStyle]="{
                'font-weight': ['/home'].includes(manu) ? '800' : ''
              }"
              class="option-menu"
              (click)="goUrl('/home'); toggleManu = false"
              >Home</a
            >
          </li>
          <li
            class="has-submenu parent-parent-menu-item"
            [ngClass]="categorySelected ? 'active' : ''"
            (click)="toggleMegaMenu()"
          >
            <a class="option-menu"
              style="display: flex; align-items: center"
              (click)="openManu('categories')"
              [ngStyle]="{ 'font-weight': categorySelected ? '800' : '' }"
            >
              Categorias
              <ion-icon slot="icon-only" name="caret-down-outline"></ion-icon>
            </a>
            <ul
              class="submenu-mobile"
              *ngIf="subManu == 'categories' && isMobile"
            >
              <li
                *ngFor="let category of categories"
                class="megamenu-column"
                [hidden]="category.children.length === 0"
              >
                <ul>
                  <li
                    class="megamenu-head"
                    (click)="goToFilter(category.id, category)"
                  >
                    <span
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      "
                      class="cursor-pointer"
                      [ngClass]="{
                        'active-item': categorySelected == category.id
                      }"
                    >
                      {{ category.name }}
                      <ion-radio
                        class="ml-2"
                        [ngClass]="
                          categorySelected == category.id ? 'radio-checked' : ''
                        "
                      ></ion-radio>
                    </span>
                  </li>
                  <li *ngFor="let children of category.children">
                    <a
                      class="sub-menu-item selected"
                      (click)="
                        goToFilter(children.id, category);
                        categorySelected = children.id
                      "
                      [ngClass]="{
                        'active-item':
                          categorySelected == children.id ||
                          categorySelected == category.id
                      }"
                      >{{ children.name }}</a
                    >
                  </li>
                  <li *ngIf="category.children.length === 0">
                    <ul>
                      <a class="sub-menu-item">No hay subcategorías</a>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="submenu megamenu" *ngIf="!isMobile">
              <li
                *ngFor="let category of categories"
                class="megamenu-column"
                [hidden]="category.children.length === 0"
              >
                <ul>
                  <li
                    class="megamenu-head"
                    (click)="goToFilter(category.id, category)"
                  >
                    <span
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      "
                      class="cursor-pointer"
                      [ngClass]="{
                        'active-item': categorySelected == category.id
                      }"
                    >
                      {{ category.name }}
                      <ion-radio
                        class="ml-2"
                        [ngClass]="
                          categorySelected == category.id ? 'radio-checked' : ''
                        "
                      ></ion-radio>
                    </span>
                  </li>
                  <li *ngFor="let children of category.children">
                    <a
                      class="sub-menu-item selected"
                      (click)="
                        goToFilter(children.id, category);
                        categorySelected = children.id
                      "
                      [ngClass]="{
                        'active-item':
                          categorySelected == children.id ||
                          categorySelected == category.id
                      }"
                      >{{ children.name }}</a
                    >
                  </li>
                  <li *ngIf="category.children.length === 0">
                    <ul>
                      <a class="sub-menu-item">No hay subcategorías</a>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <!--end navigation menu-->
      </div>
      <!--end navigation-->

      <ul class="buy-button list-none mb-0">
        <!-- <li class="dropdown inline-block relative ps-0.5">
          <div class="relative">
            <input
              [ngClass]="{
                'input-visible': searchManu,
                'input-hidden': !searchManu
              }"
              class="transition-all duration-500 ease-in-out shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
              id="search"
              type="text"
              style="min-width: 200px"
              placeholder="Buscar productos..."
            />
            <button
              class="dropdown-toggle size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-full text-orange-500"
              type="button"
              (click)="toggleSearch()"
            >
              <i data-feather="search" class="h-4 w-4"></i>
            </button>
          </div>
        </li> -->

        <li
          class="dropdown inline-block relative ps-0.5"
          (clickOutside)="closeCartModal()"
        >
          <button
            class="dropdown-toggle mr-2 size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500 border border-orange-500 text-white relative"
            type="button"
            (click)="goUrl('shopping-cart')"
          >
            <i data-feather="shopping-cart" class="h-4 w-4"></i>
            <!-- Círculo que muestra la cantidad -->
            <span *ngIf="cartItemCount !== 0 && cartItemCount != null && cartItemCount !== undefined"
              class="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
              {{ cartItemCount }}
            </span>

          </button>

          <!-- Dropdown menu -->
          <div
            class="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-64 rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800"
            [ngClass]="cart ? '' : 'hidden'"
          >
            <ul class="py-3 text-start" aria-labelledby="dropdownDefault">
              <li>
                <a
                  href="#"
                  class="flex items-center justify-between py-1.5 px-4"
                >
                  <span class="flex items-center">
                    <img
                      src="assets/images/black-print-t-shirt.jpg"
                      class="rounded shadow dark:shadow-gray-800 w-9"
                      alt=""
                    />
                    <span class="ms-3">
                      <span class="block font-semibold"
                        >Black Print T-Shirt</span
                      >
                      <span class="block text-sm text-slate-400">800 X 1</span>
                    </span>
                  </span>

                  <span class="font-semibold"
                    ><ion-icon name="star-outline"></ion-icon>
                    {{ 800 | number }}
                  </span>
                </a>
              </li>

              <li
                class="border-t border-gray-100 dark:border-gray-800 my-2"
              ></li>

              <li class="flex items-center justify-between py-1.5 px-4">
                <h6 class="font-semibold mb-0">Total:</h6>
                <h6 class="font-semibold mb-0">
                  <ion-icon name="star-outline"></ion-icon> {{ 800 | number }}
                </h6>
              </li>

              <li class="py-1.5 px-4">
                <span class="text-center block">
                  <a
                    (click)="goUrl('shopping-cart'); closeCartModal()"
                    class="py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center rounded-md bg-orange-500 border border-orange-500 text-white me-1"
                    >Ver carro de compras</a
                  >
                  <!-- <a
                    href="#"
                    class="py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center rounded-md bg-orange-500 border border-orange-500 text-white"
                    >Checkout</a
                  > -->
                </span>
                <!-- <p class="text-sm text-slate-400 mt-1">*T&C Apply</p> -->
              </li>
            </ul>
          </div>
        </li>

        <!-- <li class="inline-block ps-0.5">
          <a
            href="#"
            class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500 text-white"
          >
            <i data-feather="heart" class="h-4 w-4"></i>
          </a>
        </li> -->

        <li #menu class="dropdown inline-block relative ps-0.5" (clickOutside)="closeUserModal()">

          <button
            data-dropdown-toggle="dropdown"
            class="dropdown-toggle items-center"
            type="button"
            (click)="userModal()">

            <span
              class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full border border-orange-500 bg-orange-500 bg-transparent"
              ><i data-feather="user" class="h-4 w-4"></i
            ></span>
          </button>
          <!-- Dropdown menu -->
          <div
            class="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-48 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700"
            [ngClass]="user ? '' : 'hidden'"
          >
            <ul class="py-2 text-start">
              <li>
                <p class="text-slate-400 pt-2 px-4">Hola, {{this.userInfo?.first_name}}!</p>
              </li>
              <li>
                <p class="flex items-center font-medium py-2 px-4">
                  <i data-feather="star" class="h-4 w-4 me-2"></i>
                  Tienes
                  <span class="text-orange-500 ms-2 pr-1">{{points?.balance  | number }}</span> puntos
                </p>
              </li>
              <li>
                <a
                  (click)="goUrl('/profile', { tab: 'user' }); closeUserModal()"
                  class="flex items-center font-medium py-2 px-4 dark:text-white/70 profile-hover"
                  ><i data-feather="user" class="h-4 w-4 me-2"></i>Perfil</a
                >
              </li>
              <li>
                <a
                  (click)="goUrl('/profile', { tab: 'redemptions' }); closeUserModal()"
                  class="flex items-center font-medium py-2 px-4 dark:text-white/70 profile-hover dark:hover:text-white"
                  ><i data-feather="gift" class="h-4 w-4 me-2"></i
                  >Redenciones</a
                >
              </li>
              <li>
                <a
                  (click)="goUrl('/profile', { tab: 'transactions' }); closeUserModal()"
                  class="flex items-center font-medium py-2 px-4 dark:text-white/70 profile-hover dark:hover:text-white"
                  ><i data-feather="dollar-sign" class="h-4 w-4 me-2"></i
                  >Transacciones</a
                >
              </li>
              <li
                class="border-t border-gray-100 dark:border-gray-800 my-2"
              ></li>
              <li>
                <a
                  (click)="logout()"
                  class="flex items-center font-medium py-2 px-4 dark:text-white/70 profile-hover dark:hover:text-white"
                  ><i data-feather="log-out" class="h-4 w-4 me-2"></i>Cerrar
                  Sesión</a
                >
              </li>
            </ul>
          </div>
        </li>

        <li class="inline-block ps-0.5" *ngIf="!isMobile">
          <a
            class="size-110 inline-flex items-center justify-start tracking-wide align-middle duration-500 text-base text-center text-orange-500"
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-start; /* Alinea los textos a la izquierda */
                margin-left: 10px;
              "
            >
              <span>Hola, {{this.userInfo?.first_name ? this.userInfo?.first_name : 'Demo MallGo' }} {{this.userInfo?.last_name}}</span>
              <span style="font-size: 13px;"><ion-icon name="star-outline" color="primary" class="mr-1"></ion-icon> Tienes {{points?.balance  | number }} Puntos</span>
            </div>
          </a>
        </li>

        <!--end dropdown-->
      </ul>
    </div>
  </div>
  <!--end container-->
</nav>
<!-- <button
  id="back-to-top"
  (click)="scrollTop()"
  class="back-to-top fixed text-lg rounded-full z-10 bottom-5 end-5 size-9 text-center bg-orange-500 text-white justify-center items-center"
  style="border: 1px solid white"
  [ngClass]="isSticky ? 'flex' : 'hidden'"
>
  <i data-feather="arrow-up"></i>
</button> -->
