<div   class="p-4 sm:p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 redemptions">
  <h5 class="text-lg font-semibold mb-4">Redenciones</h5>

  <div *ngIf="isLoading" class="flex justify-center items-center">
    <ion-spinner name="crescent"></ion-spinner>
    <span class="ml-4">Cargando órdenes...</span>
  </div>

  <div *ngIf="!isLoading && orders.length === 0" class="text-center text-gray-500 dark:text-gray-400">
    No hay órdenes disponibles.
  </div>

  <div *ngIf="!isLoading && orders.length > 0">
    <div *ngFor="let order of paginatedOrders" class="p-4 sm:p-4 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mb-4">
      <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <div class="w-full sm:w-auto">
          <h6 class="text-lg font-semibold">Orden de Pedido # {{order.id}}</h6>
          <span class="text-gray-500 dark:text-gray-400">{{ order.created | date:'dd-MM-yyyy' }}</span>
          <!-- <span style="display: flex; align-items: center">
            <ion-icon name="information-circle-outline"></ion-icon>
            {{ "Tus productos podrían tardar hasta " }}
            {{ max_days_to_deliver[order.id] || 15 }}
            {{" días en llegar." }}
          </span> -->
          <br>
          <span *ngIf="order.state === 'pending_approval' || order.state === 'in_warehouse'">
            {{ order.state === 'pending_approval' ? 'Por favor, acércate a la administración para confirmar tus datos.' : 'Para reclamar tu pedido, acude a la administración del centro comercial.' }}
          </span>

        </div>
        <div class="w-full sm:w-auto text-left sm:text-right mt-2 sm:mt-0">
          <span class="text-black dark:text-white font-semibold">Total: <ion-icon  color="primary" name="star-outline"></ion-icon> {{order.amount_points | number}} puntos</span>
        </div>
      </div>
      <div class="mt-4 sm:mt-4">
        <div #orderItem *ngFor="let product of order.product_orders; let i = index" class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <div class="flex items-center w-full sm:w-auto">
            <img [src]="product?.product.images[0]?.image" alt="Imagen" class="w-12 h-12 rounded-md mr-4">
            <div>
              <h6 class="text-lg font-semibold">{{product?.product.name}}</h6>
              <span class="text-gray-500 dark:text-gray-400">Cantidad: {{product?.quantity}}</span>
              <div class="text-black dark:text-white">Total: <ion-icon  color="primary" name="star-outline"></ion-icon> {{product?.total_amount_points | number}} puntos</div>
            </div>
          </div>
          <div class="w-full sm:w-auto text-left sm:text-right mt-2 sm:mt-0">
            <span class="text-gray-500 dark:text-gray-400" *ngIf="i == 0">{{ getStatusMessage(order.state)}}</span>
            <br>
            <div style="display: flex; justify-content: flex-end; flex-direction: column;">
              <a *ngIf="product.file_link && product?.product?.is_coupon" class="text-orange-500 mt-2 cursor-pointer" [href]="product.file_link" target="_blank">
              {{ product?.product?.is_coupon ? 'Descargar bono' : 'Descargar guia' }}
              </a>
            <a (click)="goDetail(product.product.id)" class="text-orange-500 mt-2 cursor-pointer" *ngIf="product?.product?.is_active">Volver a redimir</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="grid md:grid-cols-12 grid-cols-1 mt-6" *ngIf="!isLoading && orders.length > 0">
  <div class="md:col-span-12 text-center">
    <nav aria-label="Page navigation example">
      <ul class="inline-flex items-center -space-x-px">
        <li>
          <a
            class="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 custom-hover-bg"
            (click)="changePage(currentPage - 1)"
            [class.disabled]="currentPage === 1"
          >
            <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
          </a>
        </li>
        <li *ngFor="let page of [].constructor(totalPages); let i = index">
          <a
            class="size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 custom-hover-bg"
            [ngClass]="{'bg-orange-500 text-white': currentPage === i + 1}"
            (click)="changePage(i + 1)"
            >{{ i + 1 }}</a
          >
        </li>
        <li>
          <a
            class="size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 custom-hover-bg"
            (click)="changePage(currentPage + 1)"
            [class.disabled]="currentPage === totalPages"
          >
            <ion-icon slot="icon-only" name="chevron-forward-outline"></ion-icon>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
