<div class="px-6 py-4">
  <h3 class="text-lg leading-6 font-medium text-gray-900 text-center">
    Información Adicional
  </h3>
  <form [formGroup]="additionalInfoForm" class="mt-4">
    <div *ngFor="let info of product.additional_info" class="mb-4">
      <label [for]="info.key" class="block text-sm font-medium text-gray-700">{{ info.label }}</label>
      <div class="mt-1">
        <input *ngIf="info.type === 'text'"
               [formControlName]="info.key"
               [id]="info.key"
               type="text"
               [required]="info.required"
               class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
        <input *ngIf="info.type === 'email'"
               [formControlName]="info.key"
               [id]="info.key"
               type="email"
               [required]="info.required"
               class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
        <input *ngIf="info.type === 'number'"
               [formControlName]="info.key"
               [id]="info.key"
               type="number"
               [required]="info.required"
               class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
        <select *ngIf="info.type === 'select'"
                [formControlName]="info.key"
                [id]="info.key"
                [required]="info.required"
                class="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
          <option *ngFor="let option of info.options" [value]="option">{{ option | titlecase }}</option>
        </select>
        <!-- Mensajes de error -->
        <div *ngIf="additionalInfoForm.get(info.key)?.invalid && additionalInfoForm.get(info.key)?.touched" class="text-red-500 text-sm mt-1">
          <div *ngIf="additionalInfoForm.get(info.key)?.errors?.['required']">Este campo es requerido</div>
          <div *ngIf="additionalInfoForm.get(info.key)?.errors?.['minlength']">El valor es demasiado corto</div>
          <div *ngIf="additionalInfoForm.get(info.key)?.errors?.['maxlength']">El valor es demasiado largo</div>
          <div *ngIf="additionalInfoForm.get(info.key)?.errors?.['pattern']">El formato no es válido</div>
        </div>
      </div>
    </div>
    <div class="mt-6">
      <button type="button" (click)="onSubmit()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-500 text-base font-medium text-white sm:text-sm">
        Guardar
      </button>
      <button type="button" (click)="closeModal()" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:text-sm">
        Cancelar
      </button>
    </div>
  </form>
</div>
