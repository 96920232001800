import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output, ElementRef, ViewChild } from '@angular/core';
import { RouterLink, Router, ActivatedRoute } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import * as feather from 'feather-icons';
import { ClickOutsideModule } from 'ng-click-outside';
import { HeaderComponent } from '../header/header.component';
import { CatalogService } from 'src/app/services/catalog.service';
import { Categories } from 'src/app/interfaces/category.interface';
import { ConfigService } from 'src/app/services/config.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-navbar',
  standalone: false,
  //imports: [CommonModule,RouterLink, ClickOutsideModule ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  animations: [
    trigger('toggleInput', [
      state('true', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      state('false', style({
        transform: 'translateX(100%)',
        opacity: 0
      })),
      transition('false <=> true', animate('0.5s ease-in-out'))
    ])
  ]
})
export class NavbarComponent {

  @Input() navLight: any = false;
  @Input() navClass: any;
  @Output() goUp = new EventEmitter<any>();
  @ViewChild('menu') menu!: ElementRef;

  manu: string = '';
  subManu: string = 'categories';
  toggleManu: boolean = false;
  popoverVisible = false;

  activeCategory: any = null;
  activeSubCategory: any = null;

  categories: Categories[] = []

  categorySelected!: number | null;

  // sticky-navbar
  @Input() isSticky: boolean = false;
  isMobile!: boolean;
  showMegaMenu!: boolean;
  cartItemCount!: number;
  userInfo!: any;
  points!: any;

  @HostListener('window:scroll', [])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    console.log('tsdtstststs');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = this.menu.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.closeUserModal();
    }
  }

  constructor(
    private router: Router,
    private popoverController: PopoverController,
    private catalogService: CatalogService,
    private route: ActivatedRoute,
    public configService: ConfigService,
    private authService: AuthService,
    private storageService: StorageService
  ) {

    this.getUser();
    this.getBalance();
    this.checkScreenSize()
    this.route.queryParams.subscribe(params => {
      if(params?.['id']){
        this.categorySelected = params['id'];
      }
    });

    this.catalogService.cartItemCount$.subscribe(count => {
      this.cartItemCount = count;
    });
  }

  async getBalance() {
    this.points = await this.authService.getBalance() as any;
  }

  async getUser(){
    this.userInfo = await this.storageService.getUser()
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login'])
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth <= 990; // Define el tamaño del breakpoint como prefieras
  }

  get columns() {
    const columns = [];
    const itemsPerColumn = 4;
    for (let i = 0; i < this.categories.length; i += itemsPerColumn) {
      columns.push(this.categories.slice(i, i + itemsPerColumn));
    }
    return columns;
  }

  toggleMegaMenu() {
    if (this.isMobile) {
      this.showMegaMenu = !this.showMegaMenu;
    }
  }

  goToFilter(id: number, category: Categories) {
    this.categorySelected = id;
    this.router.navigate(['/catalog'], { queryParams: { id: id , category: category.name, subCategory: category.children.find((category) => category.id == this.categorySelected)?.name }, state: {category}});
    this.toggleManu = !this.toggleManu;
  }

  isActiveCategory(category: any): boolean {
    return this.activeCategory === category;
  }

  openCategory(category: any) {
    this.activeCategory = category;
    this.activeSubCategory = null;
  }

  isCategoryActive(category: any): boolean {
    if (this.categorySelected === category.id) {
      return true;
    }
    if (category.children) {
      return category.children.some((child: any) => this.isCategoryActive(child));
    }
    return false;
  }

  isOpenSubCategory(subCategory: any): boolean {
    return this.activeSubCategory === subCategory;
  }

  ngAfterViewInit() {
    feather.replace();
    this.isSticky = window.pageYOffset > 0;
  }

  showPopover() {
    this.popoverVisible = true;
  }

  hidePopover() {
    this.popoverVisible = false;
  }

  keepPopover() {
    this.popoverVisible = true;
  }

  async ngOnInit() {
    const current = this.router.url;
    this.manu = current;
    this.subManu = current;
    window.scrollTo(0, 0);
    await  this.getCategories();

  }

  async getCategories(){
    this.categories = await this.catalogService.getCategories() as any;
  }

  goUrl(url: string, queryParams?: any) {
    this.categorySelected = null;
    this.router.navigate([url], {queryParams});
    if(this.isMobile && url.includes('catalog')){
      this.toggleManu = !this.toggleManu;
    }
  }

  onContentScroll(event: any) {
    this.isSticky = event.detail.scrollTop > 0;
  }

  openManu(item: string) {
    if(this.subManu == item){
      this.subManu = '';
    }else{
      this.subManu = item;

    }

  }

  toggleMenu() {
    this.toggleManu = !this.toggleManu;
  }

  // search- modal

  searchManu: boolean = false;
  toggleSearch() {
    this.searchManu = !this.searchManu;
  }
  closeSearchModal() {
    this.searchManu = false;
  }

  // cart-modal
  cart: boolean = false;
  cartModal() {
    this.cart = !this.cart;
  }
  closeCartModal() {
    this.cart = false;
  }

  // user-modal
  user: boolean = false;
  userModal() {
    this.user = !this.user;
  }
  closeUserModal() {
    this.user = false;
  }

  scrollTop(){
    this.goUp.emit()
  }

}
